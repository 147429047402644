/* PROFILE HTML*/
.header {
    /*padding-left:10px;*/
    padding-top: 15px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-left: 10px;
    padding-right: 10px;
}

.header nav {
    padding-top: 10px;
    padding-bottom: 20px;
}

.header nav a {
    padding-right: 3px;
}

.header-info,
.header-bar {
    display: flex;
    align-items: center;
}

.header-info {
    text-align: center;
    flex-grow: 1;
}

.header-info a h1 {
    font-size: 24px;
    max-width: 200px;
    margin-right: 10px;
}

.header-links {
    display: flex;
    align-items: center;
}

.header-links a {
    margin-left: 10px;
    display: inline-block;
    vertical-align: middle;
    margin: 0 10px;
    font-weight: bold;
    text-decoration: none;
}

.header-links a:hover {
    text-decoration: underline;
}


/* DEFAULT HTML */
.header-default {
    height: 82px;
    padding-top: 35px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding: 20px;
}

.header-default nav {
    padding-top: 10px;
    padding-bottom: 20px;
}

.header-default a {
    margin: 0 10px;
    font-weight: bold;
    text-decoration: none;
}

.header-default a:hover {
    margin: 0 10px;
    font-weight: bold;
    text-decoration: underline;
}

.header-default nav a {
    padding-right: 3px;
    text-decoration: none;
    color: #fff;
}


hr {
    line-height: 1.5;
    width: 100%;
    margin: 0;
    display: block;
    unicode-bidi: isolate;
    margin-block-start: 0.4em;
    margin-block-end: 0.6em;
    margin-inline-start: auto;
    margin-inline-end: auto;
    overflow: hidden;
    border-style: inset;
    border-width: 1px;
}


@media only screen and (max-width: 768px) {

    .header-default {
        height: 82px;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .header {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        padding-left: 10px;
        padding-right: 10px;
    }

    #header-title {
        margin: auto;
    }

    .header-info a h1 {
        width: 100% !important;
        max-width: 100% !important;
        text-align: center;
    }

    .header-links {
        text-align: center;
        margin: auto;
        padding-top: 1rem;
    }

    .header-info {
        display: block;
        width: 100%;
    }

}