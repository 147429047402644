.json-key {
    color: #7e92a2;
    font-weight: bold;
    line-height: 1.5;
}

.json-object {
    text-align: left;
    margin-left: 0px;
    line-height: 1.5;
}

.json-string {
    color: white;
    text-indent: 1em;
    line-height: 1.5;
}

.json-object,
.json-array {
    line-height: 1.5;
    margin-left: 20px;
    justify-content: flex-start;
    display: flex;
    align-items: flex-start;
    align-content: center;
    flex-direction: column;
    flex-wrap: wrap;
}

.react-autocomplete-input {
    width: 85%;
    margin: auto;
    margin-left: 35px;
    line-height: 30px;
}

.react-autocomplete-input-profile {

    margin: auto;
    margin-left: 35px;
    width: 500px;

    background-clip: padding-box;
    background-color: #fff;
    border: 1px solid rgba(0, 0, 0, 0.15);
    bottom: auto;
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
    display: block;
    font-size: 14px;
    list-style: none;
    padding: 1px;
    position: absolute;
    text-align: left;
    z-index: 20000;
}

.react-autocomplete-input-profile li {
    display: block;
    list-style-type: disc;
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    padding-inline-start: 10px;
}

.react-autocomplete-input>li,
.react-autocomplete-input-profile>li {
    cursor: pointer;
    padding: 4px;
    min-width: 100px;
}

.react-autocomplete-input>li:hover,
.react-autocomplete-input-profile>li:hover {
    cursor: pointer;
    background-color: #2b323d;
}


@media only screen and (min-width: 988px) {

    .no-results{
        margin: 0 10%;
    }
}


@media only screen and (max-width: 987px) {

    .react-autocomplete-input-profile {
        width: 40%;
    }
}


@media only screen and (max-width: 768px) {
    .react-autocomplete-input {
        width: 300px;
        margin: auto;
        margin-left: 35px;
    }

    .react-autocomplete-input-profile {
        width: 300px;
        margin: auto;
        margin-left: 35px;
    }

    .json-object {
        text-align: left;
        margin-left: 0px;
        line-height: 1.5;
    }
}


@media only screen and (max-width: 375px) {

    .react-autocomplete-input {
        width: 210px;
        margin: auto;
        margin-left: 35px;
    }

    .react-autocomplete-input-profile {
        width: 210px;
        margin: auto;
        margin-left: 35px;
    }
}