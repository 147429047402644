@charset "utf-8";

#footer{
    /*height: 3rem;*/
    margin:1rem 0px;
    font-size: 12px;
}

.footer-container{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
}

.footer-text {
    width:100%;
    margin:auto;
    justify-content: normal;
    margin: auto;
    text-align: center;
    word-break: break-word;
}

@media only screen and (max-width: 768px) {

    #footer{
        /*height: 4rem;*/
        margin:1rem 0px;
    }
}