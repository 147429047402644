code {
    font-family: "Courier New", "Helvetica", sans-serif;
}

/* Style inspiration and settings adapted from https://github.com/jgthms/web-design-in-4-minutes*/

/* Base */
html {
    font-size: 14px;
}

/* 01 Font family */
body {
    all: unset;
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
    -webkit-font-smoothing:antialiased;
    min-height: 100vh;
    height: 100vh;
}

/* 03 Spacing */
.padded {
    padding-top: 30px;
    padding-bottom: 30px;
    line-height: 30px;
}

.main-content-1 {
    padding-top: 5rem;
    margin-bottom: 4rem;
    line-height: 30px;
    text-align: center;
    flex: 1 1;
}

.main-content-2 {
    padding-top: 1rem;
    padding-left: 16px;
    padding-right: 16px;
    margin-bottom: 4rem;
    line-height: 30px;
    text-align: center;
    flex: 1 1;
}

div.main-content h2 {
    padding-top: 15px;
    padding-bottom: 5px;
}

header h1,
h5 {
    display: inline;
}

h1 {
    font-family: "Courier New", "Helvetica", sans-serif;
}

/* 04 Color and contrast */
body {
    color: #c5ccce;
    background-color: #282f3a;
    
}

h1,
h2,
strong {
    color: #dcdede;
}

header h5 {
    font-weight: lighter;
}

/* 05 Balance */
code {
    padding: 2px 4px;
    vertical-align: text-bottom;
}

/* 06 Primary color */
a {
    color: #7e92a2;
    text-decoration: none;
    margin: 0 10px;
    font-weight: bold;
}

a:hover {
    text-decoration: underline;
}

/* 07 Secondary colors */
h1,
h2,
strong {
    font-weight: 500;
}

.json-object {
    text-align: center;
    margin-left: 0px;
}

.clickable {
    color: white;
    text-indent: 1em;
    font-weight: 500;
    word-break: break-all;
}

#page-container {
    position: relative;
    min-height: 100vh;
}

#content-wrap {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
}

.wrapper {
    flex: 1 1;
    margin-bottom: 2rem;
    text-align: center;
    word-wrap: break-word;
}

.wrapper {
    margin-top: 20px;
    justify-content: center;
}

/* -------------- SEARCH BAR -----------*/

.bar {
    margin-top: 60px;
    margin: 0 auto;
    width: 100%;
    max-width: 575px;
    border-radius: 30px;
    border: 1px solid #dcdcdc;
    height: 47px;
    position:relative;
}

.searchbar {
    height: 45px;
    border: none;
    width: 500px;
    font-size: 16px;
    color: white;
    outline: none;
    background-color: #282f3a;
}

.bar:hover,
.bar:focus-within {
    box-shadow: 1px 1px 8px 1px #dcdcdc;
}

.header-info .bar {
    margin-top: 0px;
}

.title-default {
    font-size: 72px;
}

#github-handler {
    width: 80%;
}

/*
ul.react-autocomplete-input{
    max-width: 464px;
    width:80% !important;
}*/

/* -------------- END OF SEARCH BAR -----------*/

/* -------------- SEARCH BTN -----------*/

.buttons {
    margin-top: 30px;
}

.button {
    font: inherit;
    background-color: #f5f5f5;
    border: none;
    color: #707070;
    font-size: 15px;
    padding: 10px 20px;
    margin: 5px;
    border-radius: 4px;
    outline: none;
    font-weight: bold;

}

/* -------------- END SEARCH BTN -----------*/

@media only screen and (max-width: 768px) {

    .main-content {
        width: 100%;
        padding-top: 2rem;
    }

    .title-default {
        font-size: 54px !important;
        text-align: center;
        align-items: center;
    }

    .bar {
        width: 100%;
        max-width: 375px;
        margin: 0, 1rem;
        border-radius: 30px;
        border: 1px solid #dcdcdc;
       height: 44px;
    }

    .searchbar {
        height: 42px;
        border: none;
        width: 100%;
        max-width: 300px;
        font-size: 16px;
        color: white;
        outline: none;
        background-color: #282f3a;
        margin: 0 2rem;
    }

    .main-content-2 {
        padding-left: 16px;
        padding-right: 16px;
    }
}

.separator {
    line-height: 1.5;
    margin: 0;
    display: block;
    unicode-bidi: isolate;
    margin-block-start: 0.4em;
    margin-block-end: 0.6em;
    margin-inline-start: auto;
    margin-inline-end: auto;
    overflow: hidden;
    border-style: inset;
    border-width: 0.5px;
}


@media only screen and (max-width: 375px) {

    .bar {
        width: 100%;
        max-width: 275px;
        margin: 0, 1rem;
        border-radius: 30px;
        border: 1px solid #dcdcdc;
    }

    .searchbar {
        /*height: 45px;*/
        border: none;
        width: 80%;
        max-width: 210px;
        font-size: 16px;
        color: white;
        outline: none;
        background-color: #282f3a;
        margin: 0 2rem;
    }
}